import React, { Component } from 'react';
import { HashRouter, Route, Switch, Redirect } from 'react-router-dom';
// import { renderRoutes } from 'react-router-config';
import './App.scss';
import ReactNotification from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';
import 'animate.css';
// import 'animate.css/animate.compat.css'
import { history } from './history'
const loading = () => <div className="animated fadeIn pt-3 text-center">Loading...</div>;

// Containers

const DefaultLayout = React.lazy(() => import('./containers/DefaultLayout'));

// Pages
//const Login = React.lazy(() => import('./views/SMS/Search/SearchProgram'));
//const HomeLayout = React.lazy(() => import('./views/SMS/Agent/RegisterAgent'));
//const StudentRegistration = React.lazy(() => import('./views/SMS/Student/RegisterStudent'));
//const Login = React.lazy(() => import('./views/SMS/User/RegisterEmployee'));
//const Login = React.lazy(() => import('./views/SMS/User/Login'));
//const Login = React.lazy(() => import('./views/SMS/StaticPage/Home'));
//const Login = React.lazy(() => import('./views/SMS/Student/StudentVisaInformation'));
// const HomeLayout = React.lazy(() => import('./views/SMS/University/SubjectRequiredDocumantation'));
//const Login = React.lazy(() => import('./views/SMS/University/UniversityAdmissionTime'));
//const Login = React.lazy(() => import('./views/SMS/University/UniversityBasicInformation'));
//const Login = React.lazy(() => import('./views/SMS/Commission/AgentCommission'));
//const Login = React.lazy(() => import('./views/SMS/Student/StudentPersonalInformation'));
//const Login = React.lazy(() => import('./views/SMS/Student/StudentTestScore'));
//const Login = React.lazy(() => import('./views/SMS/Student/StudentEducationalInformation'));
// const HomeLayout = React.lazy(() => import('./views/SMS/Application/ApplicationListTest'));

 const HomeLayout = React.lazy(() => import('./views/SMS/StaticPage/HomeLayout'));
// const Login = React.lazy(() => import('./views/SMS/StaticPage/Login'));
const StudentRegistration = React.lazy(() => import('./views/SMS/Student/RegisterStudent'));
const Register = React.lazy(() => import('./views/Pages/Register'));
const Page404 = React.lazy(() => import('./views/Pages/Page404'));
const Page500 = React.lazy(() => import('./views/Pages/Page500'));

class App extends Component {

  constructor(props) {
    super(props);
    this.state = { loggedIn: false };
    const userCheck = localStorage.getItem('loginCheck');

    // console.log('hi');
    // console.log(userCheck);

    if (userCheck > 0) {
      this.state.loggedIn = true;
    }
  }

  render() {
    return ( <div>
      <ReactNotification />
      <HashRouter history= {history}>
        <React.Suspense fallback={loading()}>
          <Switch>
            {/* <Route path="/login" name="Home" render={props => <HomeLayout {...props} />} /> */}
            {/* <Route exact path="/login" name="Register Page" render={props => <Login {...props} />} /> */}
            {/* <Route exact path="/studentRegistration" name="Student Register Page" render={props => <StudentRegistration {...props} />} />  */}
            {/* <Route exact path="/register" name="Register Page" render={props => <Register {...props} />} /> */}
            <Route exact path="/404" name="Page 404" render={props => <Page404 {...props} />} />
            <Route exact path="/500" name="Page 500" render={props => <Page500 {...props} />} />
            {/* <Route path="/" name="Home" render={this.state.loggedIn ? (props => <DefaultLayout {...props} />) : (props => <Login {...props} />)} /> */}
            <Route path="/" name="Home" render={this.state.loggedIn ? (props => <DefaultLayout {...props} />) : (props => <HomeLayout {...props} />)} />
          </Switch>
        </React.Suspense>
      </HashRouter>
      </div>
    );

  }
}

export default App;
